import { Component } from 'react'
import XbattleCarousel from './XbattleCarousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppBar, Button, Card, CssBaseline, IconButton, ThemeProvider, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css'
import { wasmSupported } from 'xbattle-wasm';
import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Anonymous Pro',
      'monospace'
    ].join(','),
    // fontSize: 12
  }
});

// const styles = ({ spacing }: Theme) => createStyles({
//   root: {
//       flexGrow: 1,
//   },
//   menuButton: {
//       marginRight: spacing(2),
//   },
//   title: {
//       flexGrow: 1,
//   },
// })

class App extends Component<any,{ wasmSupported?: boolean }> {
  constructor(props: any) {
    super(props)

    this.setWasmSupported = this.setWasmSupported.bind(this)

    this.state = { wasmSupported: wasmSupported }
  }

  setWasmSupported(supported:boolean) {
    if (this.state.wasmSupported !== supported) {
      this.setState({wasmSupported: supported})
    }
  }

  openSinglePlayerWindow() {
    const link = document.createElement("a");
    link.href = "/singleplayer/";
    link.target = "_xbattle_single_player";
    link.click();
  }

  render() {
    const carouselAspect = 1.3
    const carouselOffset = 100
    const cardWidth = Math.min(window.innerWidth*0.9, (window.innerHeight-carouselOffset)/carouselAspect)
    return <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="static">
        <Toolbar>
            <IconButton disabled={true} edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
            </IconButton>
            <Typography variant="h6">
            XBattle
            </Typography>
        </Toolbar>
        </AppBar>
        <Card style={{width: cardWidth, margin: 'auto', marginTop: '4px', textAlign: 'center'}}>
          <Button onClick={this.openSinglePlayerWindow} color='primary' style={{ margin: '10px' }} variant='contained'>PLAY AGAINST THE AI NOW</Button><br />
          Multi-player version coming soon<br/>
          { this.state.wasmSupported
            ? <></>
            : <span className='highlight'>use a WASM capable browser to see this come to life!</span>
          }
            <XbattleCarousel setWasmSupported={this.setWasmSupported} />
        </Card>
        <Card style={{width: cardWidth, margin: 'auto', textAlign: 'center'}}>
          XBattle is a concurrent multi-player game that combines elements of strategy with arcade&#8209;like action to capture a wide range of military scenarios.<br/>
          For more info contact <a href="mailto:interest@xbattle.online">interest@xbattle.online</a>
        </Card>
      </ThemeProvider>
    </>
  }
}

export default App;
