import { Carousel } from "react-responsive-carousel";
import { XbattleGame, wasmSupported } from "xbattle-wasm";
import { ErrorBoundary } from "./ErrorBoundary";

function XbattleCarousel(props) {
  const { setWasmSupported } = props

  return <Carousel autoPlay infiniteLoop={true} showThumbs={false} interval={16000}>
    <div>
      <ErrorBoundary inError={!wasmSupported} setWasmSupported={setWasmSupported}>
        <XbattleGame options="-black me -white null -ai 0 1 1 -ai 1 1 1 -hex -hills 5 -sea 2 -rbases 3 -cell 24 -win_trad" />
        <img alt="" src="/hex_terrain_black_and_white.png" />
      </ErrorBoundary>
      <p className="legend">hexagonal board featuring hills and sea</p>
    </div>
    <div>
      <ErrorBoundary inError={!wasmSupported} setWasmSupported={setWasmSupported}>
        <XbattleGame options="-black me -white null -red null -green null -ai 0 1 1 -ai 1 1 1 -ai 2 1 1 -ai 3 1 1 -bases 3 -cell 24 -win_trad" />
        <img alt="" src="/square_4_player_bases.png" />
      </ErrorBoundary>
      <p className="legend">square board with 4 players and regularly distrubuted bases</p>
    </div>
    <div>
      <ErrorBoundary inError={!wasmSupported} setWasmSupported={setWasmSupported}>
        <XbattleGame options="-black me -white null -ai 0 1 1 -ai 1 1 1 -forest 5 -rbases 3 -towns 1 -cell 24 -win_trad" />
        <img alt="" src="/square_forest.png" />
      </ErrorBoundary>
      <p className="legend">Forest terrain with random towns and bases</p>
    </div>
    <div>
      <ErrorBoundary inError={!wasmSupported} setWasmSupported={setWasmSupported}>
        <XbattleGame options="-black me -white null -ai 0 1 1 -ai 1 1 1 -hills 5 -sea 2 -rbases 3 -towns 1 -map -cell 24 -win_trad" />
        <img alt="" src="/square_hills_sea_fog_of_war.png" />
      </ErrorBoundary>
      <p className="legend">terrain featuring hills and sea with fog of war</p>
    </div>
  </Carousel>
};

export default XbattleCarousel